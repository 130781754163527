import React from 'react';
import i18next from 'i18next';
import './RatingReviewsFilter.scss';
import moment from 'moment';

const sortOrder = [
  ['rates', 'Notes'],
  ['high', 'Note - élevée à faible'],
  ['low', 'Note - faible à élevée'],
];

const dateOrder = [
  ['dates', 'Dates'],
  ['new', 'Date - du plus récent au plus ancien'],
  ['old', 'Date - du plus ancien au plus récent'],
];

const filters = [
  ['allScores', 'Toute les notes'],
  ['5', '5 étoiles'],
  ['4', '4 étoiles'],
  ['3', '3 étoiles'],
  ['2', '2 étoiles'],
  ['1', '1 étoile'],
];

const cumulableFilter = [];
let newDataFiltered;
let firstPropsComment;

class RatingReviewsFilter extends React.Component {
  state = {
    sortingOrder: sortOrder[0][0],
    sortingDateOrder: dateOrder[0][0],
    scoresFilter: filters[0][0],
    isScoreActive: false,
    isRateActive: false,
    isDateActive: false,
    isRateForScore: false,
  };

  componentDidMount() {
    firstPropsComment = this.props.comments.sort((a, b) => {
      return moment(b.item.date.value).format('X') - moment(a.item.date.value).format('X');
    });
    this.props.onFilterChange(firstPropsComment);
  }

  // Functions to filter by Score
  changeFilterOption = (e, filterType) => {
    let valueOption = e.currentTarget.value;
    this.handleFilters(valueOption, filterType);

    if (valueOption === '5') {
      newDataFiltered = firstPropsComment.filter((itm) => itm.item.rating.numberValue === 5);
    } else if (valueOption === '4') {
      newDataFiltered = firstPropsComment.filter((itm) => itm.item.rating.numberValue === 4);
    } else if (valueOption === '3') {
      newDataFiltered = firstPropsComment.filter((itm) => itm.item.rating.numberValue === 3);
    } else if (valueOption === '2') {
      newDataFiltered = firstPropsComment.filter((itm) => itm.item.rating.numberValue === 2);
    } else if (valueOption === '1') {
      newDataFiltered = firstPropsComment.filter((itm) => itm.item.rating.numberValue === 1);
    } else {
      if (cumulableFilter.length > 0) {
        cumulableFilter.map((filter) => {
          if (filter.filterType === 'sortingDateOrder') {
            this.changeSortOption('', filter.filterType, filter.selectedOption);
          }
        });
      } else {
        newDataFiltered = firstPropsComment;
      }
    }

    this.props.onFilterChange(newDataFiltered);
  };

  scoreActive(value) {
    this.setState({
      isScoreActive: value,
    });
  }

  // Functions to sort Order / Date
  changeSortOption = (e, filterType, isScore) => {
    let valueOption;
    if (e) {
      valueOption = e.currentTarget.value;
    }
    if (valueOption) {
      this.handleFilters(valueOption, filterType);
    }

    if (filterType === 'sortingOrder') {
      if (valueOption === 'high' || isScore === 'high') {
        newDataFiltered = firstPropsComment.sort((a, b) => {
          return b.item.rating.numberValue - a.item.rating.numberValue;
        });
        this.setState({
          isRateActive: true,
          isRateForScore: true,
        });
      } else if (valueOption === 'low' || isScore === 'low') {
        newDataFiltered = firstPropsComment.sort((a, b) => {
          return a.item.rating.numberValue - b.item.rating.numberValue;
        });
        this.setState({
          isRateActive: true,
          isRateForScore: true,
        });
      } else if (valueOption === 'rates' || isScore === 'rates') {
        newDataFiltered = firstPropsComment.sort((a, b) => {
          return moment(b.item.date.value).format('X') - moment(a.item.date.value).format('X');
        });
        this.setState({
          isRateActive: false,
          isRateForScore: false,
        });
      }
    } else if (filterType === 'sortingDateOrder') {
      if (newDataFiltered) {
        if (valueOption === 'new' || isScore === 'new') {
          if (isScore) {
            newDataFiltered = firstPropsComment.sort((a, b) => {
              return moment(b.item.date.value).format('X') - moment(a.item.date.value).format('X');
            });
          } else {
            newDataFiltered = newDataFiltered.sort((a, b) => {
              return moment(b.item.date.value).format('X') - moment(a.item.date.value).format('X');
            });
          }
          this.setState({
            isDateActive: true,
          });
        } else if (valueOption === 'old' || isScore === 'old') {
          if (isScore) {
            newDataFiltered = firstPropsComment.sort((a, b) => {
              return moment(a.item.date.value).format('X') - moment(b.item.date.value).format('X');
            });
          } else {
            newDataFiltered = newDataFiltered.sort((a, b) => {
              return moment(a.item.date.value).format('X') - moment(b.item.date.value).format('X');
            });
          }
          this.setState({
            isDateActive: true,
          });
        } else if (valueOption === 'dates' || isScore === 'dates') {
          newDataFiltered = firstPropsComment.sort((a, b) => {
            return moment(b.item.date.value).format('X') - moment(a.item.date.value).format('X');
          });
          this.setState({
            isDateActive: false,
          });
        }
      } else {
        if (valueOption === 'new' || isScore === 'new') {
          newDataFiltered = firstPropsComment.sort((a, b) => {
            return moment(b.item.date.value).format('X') - moment(a.item.date.value).format('X');
          });
          this.setState({
            isDateActive: true,
          });
        } else if (valueOption === 'old' || isScore === 'old') {
          newDataFiltered = firstPropsComment.sort((a, b) => {
            return moment(a.item.date.value).format('X') - moment(b.item.date.value).format('X');
          });
          this.setState({
            isDateActive: true,
          });
        } else if (valueOption === 'dates' || isScore === 'dates') {
          newDataFiltered = firstPropsComment.sort((a, b) => {
            return moment(b.item.date.value).format('X') - moment(a.item.date.value).format('X');
          });
          this.setState({
            isDateActive: false,
          });
        }
      }
    }

    this.props.onFilterChange(newDataFiltered);
  };

  /*
        Add or Remove selectedOption in the tab cumulableFilter
        @selectedOption: value of the option
        @filtlerType: name of the filter
    */
  handleFilters(selectedOption, filterType) {
    if (cumulableFilter.length !== 0) {
      let indexOfItem = cumulableFilter.findIndex((obj) => obj.filterType === filterType);
      let isOrderAndScore = cumulableFilter.some((e) => e.filterType === 'sortingOrder');

      // Find if the new item is already in the array
      if (cumulableFilter.some((e) => e.filterType === filterType)) {
        if (
          selectedOption === 'allScores' ||
          selectedOption === 'rates' ||
          selectedOption === 'dates'
        ) {
          cumulableFilter.splice(indexOfItem, 1);
          if (filterType === 'scoresFilter' && selectedOption === 'allScores') {
            this.scoreActive(false);
          }
        } else {
          if (filterType === 'scoresFilter' && isOrderAndScore) {
            cumulableFilter.splice(indexOfItem, 1);
            cumulableFilter.splice(indexOfItem, 1, { selectedOption, filterType });
            this.scoreActive(true);
          } else {
            cumulableFilter.splice(indexOfItem, 1, { selectedOption, filterType });
            if (filterType === 'scoresFilter') {
              this.scoreActive(true);
            }
          }
        }
      } else {
        if (isOrderAndScore && filterType === 'scoresFilter') {
          cumulableFilter.splice(indexOfItem, 1);
          cumulableFilter.splice(indexOfItem, 1, { selectedOption, filterType });
          this.scoreActive(true);
        } else {
          cumulableFilter.push({ selectedOption, filterType });
          if (filterType === 'scoresFilter') {
            this.scoreActive(true);
          }
        }
      }
    } else {
      cumulableFilter.push({ selectedOption, filterType });
      if (filterType === 'scoresFilter') {
        this.scoreActive(true);
      }
    }
  }

  render() {
    return (
      <div className="RatingReviewsFilter">
        <div className="column">
          <p className={'label_title'}>{i18next.t('filter-by')}</p>
          {this.state.isRateForScore ? (
            <select disabled>
              {filters.map((filtr, index) => {
                return (
                  <option value={filtr[0]} key={index}>
                    {filtr[1]}
                  </option>
                );
              })}
            </select>
          ) : (
            <select onChange={(e) => this.changeFilterOption(e, 'scoresFilter')}>
              {filters.map((filtr, index) => {
                return (
                  <option value={filtr[0]} key={index}>
                    {filtr[1]}
                  </option>
                );
              })}
            </select>
          )}
        </div>

        <div className="column">
          <p className={'label_title'}>{i18next.t('order-by')}</p>
          {this.state.isScoreActive || this.state.isDateActive ? (
            <select disabled>
              {sortOrder.map((filtr, index) => {
                return (
                  <option value={filtr[0]} key={index}>
                    {filtr[1]}
                  </option>
                );
              })}
            </select>
          ) : (
            <select onChange={(e) => this.changeSortOption(e, 'sortingOrder')}>
              {sortOrder.map((filtr, index) => {
                return (
                  <option value={filtr[0]} key={index}>
                    {filtr[1]}
                  </option>
                );
              })}
            </select>
          )}
          {this.state.isRateActive ? (
            <select disabled>
              {dateOrder.map((filtr, index) => {
                return (
                  <option value={filtr[0]} key={index}>
                    {filtr[1]}
                  </option>
                );
              })}
            </select>
          ) : (
            <select onChange={(e) => this.changeSortOption(e, 'sortingDateOrder')}>
              {dateOrder.map((filtr, index) => {
                return (
                  <option value={filtr[0]} key={index}>
                    {filtr[1]}
                  </option>
                );
              })}
            </select>
          )}
        </div>
      </div>
    );
  }
}

export default RatingReviewsFilter;
