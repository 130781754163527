import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import './HeadingBanner.scss';

const HeadingBanner = (props) => {
  const blockClasses = {
    backgroundImage: 'url(' + props.fields.backgroundImage.value.src + ')',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  };

  if (props.fields.Layout2.value) {
    // Layout with text under image on mobile
    return (
      <div className={'heading-background layout2 ' + props.fields.designVariant.value}>
        <div className="img-ctn bg-2" style={blockClasses} />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-wrap">
                <div className="page-title">
                  <Text field={props.fields.title} tag="h1" className={'title'} />
                  <div className="subTitle">
                    <RichText field={props.fields.subtitle} />{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={'heading-background bg-2 ' + props.fields.designVariant.value}
        style={blockClasses}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-wrap">
                <div className="page-title">
                  <Text field={props.fields.title} tag="h1" className={'title'} />
                  <div className="subTitle">
                    <RichText field={props.fields.subtitle} />{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default HeadingBanner;
