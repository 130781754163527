import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import StarRating from '../StarRating';
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";
import {ScaleLoader} from "react-spinners";
import {Query} from "react-apollo";

const ProductRatingQuery = gqlLoader('./query.graphql');

class ProductRating extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  render() {
    return <StarRating value={parseInt(this.props.productid.rating.value)} isLittleSvg={true} />;
  }
}

export default withSitecoreContext()(ProductRating);
