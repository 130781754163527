import React from 'react';
import {RichText, Text} from "@sitecore-jss/sitecore-jss-react";
import './youtubeVideo.scss';

const YoutubeVideo = (props) => (
  <div className={'youtubeVideo'}>
    <Text field={props.fields && props.fields.Title} tag={'h2'} className={'titleVideo'} />
    <RichText
      field={props.fields && props.fields.Description}
      tag={'p'}
      className={'descriptionVideo'}
    />
    <iframe
      loading="lazy"
      id="ytplayer"
      className={
        props.fields && props.fields.FullWidth.value ? 'fullWidthOption' : 'centerWidthOption'
      }
      src={
        props.fields.Url.value.replace('https://youtu.be/', 'https://www.youtube.com/embed/') +
        (props.fields.Autoplay.value ? '?autoplay=1' : '')
      }
    />
  </div>
);

export default YoutubeVideo;
