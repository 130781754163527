import React from 'react';
import { Image, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from '../../ReactComponent';
import i18next from 'i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './relatedProductsCarousel.scss';
import StarRating from '../StarRating';
import ProductReviews from '../../utils/ProductReviews';
import NavLinkAdv from '../../utils/NavLinkAdv';
import { productClickTagging, handleSliderButtonClick } from '../Tagging';

class RelatedProductsCarousel extends ReactComponent {
  state = {
    isLoaded: false,
  };

  // If necessary, it give us one number after the "," to have half star
  calcNumberValue(value) {
    return Math.round(value * 10) / 10;
  }

  render() {
    const datasource = this.props.fields.ProductList;

    if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
      const designVariant =
        typeof this.props.fields.designVariant === 'undefined'
          ? ''
          : this.props.fields.designVariant.value;
      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        draggable: false,
        beforeChange: () => handleSliderButtonClick(this.props.sitecoreContext.route.fields.pageCategoryGTM.value, this.props.fields.Heading.value),
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };

      return (
        <div className={'margin_custom rpc ' + designVariant}>
          <div className={'row related__product__custom'}>
            <div className="heading">
              <Text field={this.props.fields.Heading} tag={'h2'} />
              <Text field={this.props.fields.SubHeading} tag={'h3'} />
            </div>
            <div className="hide-left" />
            <Slider {...settings} className={'sliderCustom'}>
              {this.props.fields.ProductList.map((item, index) => (
                <div
                  key={`index-${index}`}
                  className="portfolio-item text-cente productTag"
                  data-gtm-name={item.fields.title.value}
                  data-gtm-ean={item.fields.codeEan.value}
                  data-gtm-category={item.fields.productCategories[1].name}
                  data-gtm-rating={item.fields.rating.value}
                  data-gtm-list={this.props.sitecoreContext.route.templateName === "App Route" ? "highlight-product" : "cross-sell"}
                  data-gtm-position={index+1}
                  onClick={() =>
                    productClickTagging(
                      item.fields.title.value,
                      item.fields.codeEan.value,
                      item.fields.productCategories[1].name,
                      item.fields.rating.value,
                      this.props.sitecoreContext.route.templateName === "App Route" ? "highlight-product" : "cross-sell",
                      index+1
                    )
                  }
                >
                  <NavLinkAdv to={item.url} className="portfolio-link">
                    <div className={'imgSlide'}>
                      <Image loading="lazy" field={item.fields.image1} />
                    </div>
                    <div className="portfolio-caption">
                      <Text field={item.fields.title} tag="h2" className={'titleProduct'} />

                      <Text
                        field={item.fields?.tag[0]?.fields.title}
                        tag="h3"
                        style={{
                          backgroundColor: item.fields?.tag[0]?.fields.backgroundColor.value,
                        }}
                        className={'tags'}
                      />
                      {item.fields.rating.value !== 0 && (
                        <div className="stars">
                          <StarRating
                            value={this.calcNumberValue(item.fields.rating.value)}
                            isLittleSvg={true}
                          />{' '}
                          &nbsp;
                          <span>{this.calcNumberValue(item.fields.rating.value)}</span> &nbsp;
                          <ProductReviews productId={item.id.replace(/-/g, '').toLowerCase()} />
                        </div>
                      )}
                    </div>
                    <div className="btn btn_custom_oro">{i18next.t('see-more')}</div>
                  </NavLinkAdv>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      );
    } else {
      return (
        <div className="component-no-datasource">
          {i18next.t('no-datasource') +
            ' ' +
            this.props.rendering.componentName +
            ' ' +
            i18next.t('component')}
        </div>
      );
    }
  }
}

export default withSitecoreContext()(RelatedProductsCarousel);
