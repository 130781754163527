import React from 'react';
import { Image, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './headermultilevel.scss';
import ReactComponent from '../../ReactComponent';
import NavLinkAdv from '../../utils/NavLinkAdv/index';
import i18next from 'i18next';

class HeaderMultilevel extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.addClassMobile = this.addClassMobile.bind(this);
  }

  addClassMobile() {
    this.setState({
      menu: !this.state.menu,
    });
  }

  setSearchState(listItem, productPageLink) {
    let categoryList = [];
    let categoryNameList = [];
    categoryList.push(listItem.id.replace(/-/g, ''));
    categoryNameList.push(listItem.fields.title.value);
    sessionStorage.setItem('filterCategories', JSON.stringify(categoryList));
    sessionStorage.setItem('filterCategoriesName', JSON.stringify(categoryNameList));
    window.location.href =
      productPageLink &&
      productPageLink.value.href + '?tagId=' + listItem.id.split('-').join('').toLowerCase();
  }

  render() {
    const outlineNone = {
      outline: 'none',
    };
    return (
      <div className="row_custom_small nav-hd">
        {/* Mobile Menu */}
        <div className="slicknav_menu">
          <p
            aria-haspopup="true"
            tabIndex="0"
            className="slicknav_btn slicknav_collapsed"
            style={outlineNone}
          >
            <span className="slicknav_menutxt">{i18next.t('menu')}</span>
            <span
              className={
                this.state.menu
                  ? 'slicknav_icon collapsed changeHamburger'
                  : 'slicknav_icon collapsed'
              }
              data-toggle="collapse"
              data-target="#navigation"
              onClick={this.addClassMobile}
            >
              <span className="slicknav_icon-bar"></span>
              <span className="slicknav_icon-bar"></span>
            </span>
          </p>
          <ul className="slicknav_nav slicknav_hidden collapse" id="navigation">
            {this.props.fields.HeaderMenu &&
              this.props.fields.HeaderMenu.map((mainMenu, mainindex) => (
                <li
                  className="menu-item slicknav_parent slicknav_collapsed "
                  aria-haspopup="true"
                  tabIndex="0"
                  key={`first-${mainindex}`}
                >
                  {mainMenu.fields.InternalLink.value.href != '' &&
                    mainMenu.fields.InternalLink.value.href && (
                      <NavLinkAdv
                        to={mainMenu.fields.InternalLink && mainMenu.fields.InternalLink.value.href}
                        className="collapsed"
                        data-toggle="collapse"
                        data-target={`#menu${mainindex}`}
                      >
                        <Text field={mainMenu.fields && mainMenu.fields.Title} />
                      </NavLinkAdv>
                    )}
                  {mainMenu.fields.SubMenuLinks.length > 0 && (
                    <ul
                      className="sub-menu slicknav_hidden collapse first_level row"
                      role="menu"
                      aria-hidden="true"
                      id={`menu${mainindex}`}
                    >
                      {mainMenu.fields.SubMenuLinks &&
                        mainMenu.fields.SubMenuLinks.map((submenu, subindex) => (
                          <li
                            className="col-md-4 child-nav slicknav_collapsed slicknav_parent "
                            key={subindex}
                            aria-haspopup="true"
                            tabIndex="0"
                          >
                            <div
                              role="menuitem"
                              className="slicknav_item slicknav_row slicknav_collapsed"
                              style={outlineNone}
                            >
                              {submenu.fields.SubMenuLinks &&
                                submenu.fields.SubMenuLinks.length > 0 && (
                                  <>
                                    {submenu.fields.InternalLink.value.href !== '' &&
                                      submenu.fields.InternalLink.value.href && (
                                        <NavLinkAdv
                                          to={
                                            submenu.fields.InternalLink &&
                                            submenu.fields.InternalLink.value.href
                                          }
                                          className="collapsed"
                                          data-toggle="collapse"
                                          data-target={`#subind_${subindex}`}
                                        >
                                          <Text field={submenu.fields && submenu.fields.Title} />
                                        </NavLinkAdv>
                                      )}
                                    <ul
                                      className="sub-menu slicknav_hidden collapse"
                                      id={`subind_${subindex}`}
                                    >
                                      {submenu.fields.SubMenuLinks &&
                                        submenu.fields.SubMenuLinks.map((sublink, sublinkindex) => (
                                          <li
                                            className="menu-item tiret__custom "
                                            key={sublinkindex}
                                          >
                                            {sublink.fields.InternalLink.value.href !== '' &&
                                              sublink.fields.InternalLink.value.href && (
                                                <NavLinkAdv
                                                  to={
                                                    sublink.fields.InternalLink &&
                                                    sublink.fields.InternalLink.value.href
                                                  }
                                                >
                                                  <Text
                                                    field={sublink.fields && sublink.fields.Title}
                                                  />
                                                </NavLinkAdv>
                                              )}
                                          </li>
                                        ))}
                                    </ul>
                                  </>
                                )}

                              {/* Sub Menu For CategoriesList */}
                              {submenu.fields.CategoriesList &&
                                submenu.fields.CategoriesList.length > 0 && (
                                  <>
                                    {submenu.fields.Title.value != '' && (
                                      <NavLinkAdv
                                        to={
                                          submenu.fields.InternalLink &&
                                          submenu.fields.InternalLink.value.href
                                        }
                                        className="collapsed"
                                        data-toggle="collapse"
                                        data-target={`#subind_${subindex}`}
                                      >
                                        <Text field={submenu.fields.Title} />
                                      </NavLinkAdv>
                                    )}
                                    <ul
                                      className="sub-menu slicknav_hidden collapse"
                                      id={`subind_${subindex}`}
                                    >
                                      {submenu.fields.CategoriesList &&
                                        submenu.fields.CategoriesList.map(
                                          (sublink, sublinkindex) => (
                                            <li
                                              className="menu-item tiret__custom "
                                              key={sublinkindex}
                                            >
                                              <a
                                                className="cat_nav "
                                                onClick={this.setSearchState.bind(
                                                  this,
                                                  sublink,
                                                  submenu.fields && submenu.fields.ProductPageLink
                                                )}
                                              >
                                                <Text
                                                  field={sublink.fields && sublink.fields.title}
                                                />
                                              </a>
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  </>
                                )}
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
        </div>

        <div className="col-12 text-center">
          <NavLinkAdv to="/" className="logo">
            <Image
              loading="lazy"
              field={this.props.fields.SiteLogo}
              width="278"
              height="30"
              className="img_custom_full"
            />
          </NavLinkAdv>
        </div>

        {/* Desktop Menu */}
        <div className="col-12 nav-cn">
          <nav>
            <ul className="menu-hd">
              {this.props.fields.HeaderMenu &&
                this.props.fields.HeaderMenu.map((mainMenu, mainindex) => (
                  <li
                    className={`${
                      mainMenu.fields.SubMenuLinks.length > 0 ? 'menu-item-has-children ' : ''
                    }`}
                    key={mainindex}
                  >
                    {mainMenu.fields.InternalLink.value.href != '' &&
                      mainMenu.fields.InternalLink.value.href && (
                        <NavLinkAdv
                          to={
                            mainMenu.fields.InternalLink && mainMenu.fields.InternalLink.value.href
                          }
                        >
                          <Text field={mainMenu.fields && mainMenu.fields.Title} />
                        </NavLinkAdv>
                      )}
                    {mainMenu.fields.SubMenuLinks.length > 0 && (
                      <ul className="sub-menu first_level">
                        {mainMenu.fields.SubMenuLinks &&
                          mainMenu.fields.SubMenuLinks.map((submenu, subindex) => (
                            <li
                              className={`menu-item ${
                                mainMenu.fields.ShowSubmenu.value == '1'
                                  ? 'col-4 menu-item-has-children'
                                  : ''
                              } ${
                                mainMenu.fields.SubMenuLinks.length == subindex + 1
                                  ? 'no_border__custom'
                                  : ''
                              }`}
                              key={subindex}
                            >
                              {submenu.fields.InternalLink.value.href != '' &&
                                submenu.fields.InternalLink.value.href && (
                                  <NavLinkAdv
                                    to={
                                      submenu.fields.InternalLink &&
                                      submenu.fields.InternalLink.value.href
                                    }
                                  >
                                    <Text field={submenu.fields && submenu.fields.Title} />
                                  </NavLinkAdv>
                                )}
                              {submenu.fields.SubMenuLinks &&
                                submenu.fields.SubMenuLinks.length > 0 && (
                                  <ul className="sub-menu second_level">
                                    {submenu.fields.SubMenuLinks &&
                                      submenu.fields.SubMenuLinks.map((sublink, sublinkindex) => (
                                        <li className="menu-item" key={sublinkindex}>
                                          {sublink.fields.InternalLink.value.href != '' &&
                                            sublink.fields.InternalLink.value.href && (
                                              <NavLinkAdv
                                                to={
                                                  sublink.fields.InternalLink &&
                                                  sublink.fields.InternalLink.value.href
                                                }
                                              >
                                                <Text
                                                  field={sublink.fields && sublink.fields.Title}
                                                />
                                              </NavLinkAdv>
                                            )}
                                        </li>
                                      ))}
                                  </ul>
                                )}
                              {/* Sub Menu For CategoriesList */}
                              {submenu.fields.CategoriesList &&
                                submenu.fields.CategoriesList.length > 0 && (
                                  <>
                                    <ul className="sub-menu">
                                      {submenu.fields.CategoriesList &&
                                        submenu.fields.CategoriesList.map(
                                          (sublink, sublinkindex) => (
                                            <li className="menu-item" key={sublinkindex}>
                                              {submenu.fields.InternalLink &&
                                                submenu.fields.InternalLink.value &&
                                                submenu.fields.InternalLink.value.href && (
                                                  <a
                                                    className="cat_nav "
                                                    onClick={this.setSearchState.bind(
                                                      this,
                                                      sublink,
                                                      submenu.fields.ProductPageLink
                                                    )}
                                                  >
                                                    <Text
                                                      field={sublink.fields && sublink.fields.title}
                                                    />
                                                  </a>
                                                )}
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  </>
                                )}
                            </li>
                          ))}
                      </ul>
                    )}
                  </li>
                ))}
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(HeaderMultilevel);
