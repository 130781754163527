import React from 'react';
import './globalSearch.scss';
import { RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from '../../ReactComponent';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';
import { Query } from 'react-apollo';
import { loader as gqlLoader } from 'graphql.macro';
import { DotLoader } from 'react-spinners';
import Pagination from '../../utils/Pagination';
import { observeProductFinder, handleInternalSearch, productClickTagging } from '../Tagging';

const GlobalSearchQuery = gqlLoader('./globalSearchQuery.graphql');

class GlobalSearch extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      after: '0',
      nbOfItemsToShow: parseInt(this.props.fields.itemsPerPage.value),
      currentPage: 1,
      refreshPagination: false,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.resetKeyword = this.resetKeyword.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const url = decodeURI(window.location.href);
    let keyword = '';

    if (url.includes('?search=')) {
      keyword = url.substring(url.indexOf('=') + 1);
      if (keyword.includes('#')) keyword = keyword.replace('#', '');
      this.setState({
        keyword: keyword,
      });
    }

    setTimeout(function(){
      //GTM
      handleInternalSearch(keyword);
    }, 500)
  }

  handleSearch(e) {
    this.setState({
      keyword: e.target.value,
      refreshPagination: true,
    });
    handleInternalSearch(e.target.value);
  }

  resetKeyword() {
    this.setState({
      keyword: '',
    });
  }

  handleChange(data) {
    observeProductFinder(this.props.sitecoreContext); // GTM tagging (product impression)
    this.setState({
      after: data.after.toString(),
      currentPage: data.currentPage,
      refreshPagination: false,
    });
  }

  render() {
    const datasource = this.props.fields || {};
    if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
      const designVariant =
        typeof this.props.fields.designVariant === 'undefined'
          ? ''
          : this.props.fields.designVariant.value;

      return (
        <React.Fragment>
          <div className={'heading-container globalSearch ' + designVariant}>
            <div
              className="heading-background"
              style={{
                backgroundImage: `url(` + this.props.fields.backgroundImage.value.src + `)`,
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="heading-wrap">
                      <div className="page-title">
                        <Text tag="h1" field={this.props.fields.title} />
                      </div>
                      <div className="contain-search">
                        <form className="searchBar">
                          <input
                            type="search"
                            placeholder={i18next.t('globalSearch')}
                            value={this.state.keyword}
                            onChange={this.handleSearch}
                          />
                          <input
                            type="submit"
                            id="globalSearchSubmit"
                            className="hidden"
                            name="search"
                            value={this.state.keyword}
                          />
                          <i
                            onClick={() => this.resetKeyword()}
                            className={this.state.keyword === '' ? 'hidden' : 'fa fa-close'}
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="globalItems">
            <div className="row">
              <div className="main-wrap">
                <div className="main-content">
                  <div className="posts" data-layout="grid">
                    <div className="posts-wrap posts-layout-grid row">
                      <Query
                        query={GlobalSearchQuery}
                        variables={{
                          indexname: this.indexName,
                          rootPath: 'sitecore/content/' + this.appName + '/',
                          first: this.state.nbOfItemsToShow,
                          after: this.state.after,
                          keyword: this.state.keyword,
                        }}
                      >
                        {({ loading, error, data }) => {
                          if (loading)
                            return (
                              <div
                                className="sweet-loading"
                                style={{ marginTop: '50px', marginBottom: '50px' }}
                              >
                                <DotLoader
                                  sizeUnit={'px'}
                                  size={25}
                                  color={'#003D0E'}
                                  loading={!this.state.isLoaded}
                                />
                              </div>
                            );

                          if (error) return <div>Error: {error.message}</div>;

                          let totalItems = data.search.results.totalCount;

                          return (
                            <React.Fragment>
                              {data && data.search.results.items ? (
                                data.search.results.items.map((result, index) => {
                                  if (result.templateName === 'ProductRoute') {
                                    const productCategory = result.item.productCategories
                                      ?.targetItems[0].title
                                      ? result.item.productCategories.targetItems[0].title.value
                                      : '';
                                    return (
                                      <div key={'post' + index} className="col-md-4 hentry">
                                        <div
                                          className="hentry-wrap productTag"
                                          data-gtm-name={result.item.name}
                                          data-gtm-ean={result.item.codeEan?.value}
                                          data-gtm-category={productCategory}
                                          data-gtm-rating={result.item.rating?.numberValue}
                                          data-gtm-list="list-result-search"
                                          data-gtm-position={index+1}
                                          onClick={() =>
                                            productClickTagging(
                                              result.item.name,
                                              result.item.codeEan?.value,
                                              productCategory,
                                              result.item.rating?.numberValue,
                                              "list-result-search",
                                              index+1
                                            )
                                          }
                                        >
                                          <NavLinkAdv to={result.item.url}>
                                            <img
                                              src={result.item.image1 && result.item.image1.src}
                                              alt={result.item.image1 && result.item.image1.alt}
                                            />
                                            <Text
                                              field={result.item.title && result.item.title}
                                              tag={'h2'}
                                              className={'title'}
                                            />
                                            {/* <RichText field={result.item.articleContent || result.item.productShortDescription} tag={"div"} className={'content'}/> */}
                                            <div className={'link'}>{i18next.t('see-more')}</div>
                                          </NavLinkAdv>
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div key={'post' + index} className="col-md-4 hentry">
                                        <div className="hentry-wrap">
                                          <NavLinkAdv to={result.item.url}>
                                            <img
                                              src={
                                                result.item.articleImage &&
                                                result.item.articleImage.src
                                              }
                                              alt={
                                                result.item.articleImage &&
                                                result.item.articleImage.alt
                                              }
                                            />
                                            <Text
                                              field={
                                                result.item.articleTitle && result.item.articleTitle
                                              }
                                              tag={'h2'}
                                              className={'title'}
                                            />
                                            {/* <RichText field={result.item.articleContent || result.item.productShortDescription} tag={"div"} className={'content'}/> */}
                                            <div className={'link'}>{i18next.t('see-more')}</div>
                                          </NavLinkAdv>
                                        </div>
                                      </div>
                                    );
                                  }
                                })
                              ) : (
                                <p>{i18next.t('no-results-found')}</p>
                              )}
                              <Pagination
                                refreshPagination={this.state.refreshPagination}
                                totalItems={totalItems}
                                nbOfItemsToShow={this.state.nbOfItemsToShow}
                                onChange={this.handleChange}
                                currentPage={this.state.currentPage}
                              />
                            </React.Fragment>
                          );
                        }}
                      </Query>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div className="component-no-datasource">
          {i18next.t('no-datasource') +
            ' ' +
            this.props.rendering.componentName +
            ' ' +
           i18next.t('component')}
        </div>
      );
    }
  }
}

export default withSitecoreContext()(GlobalSearch);
