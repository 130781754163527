import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from '../../ReactComponent';
import { DotLoader } from 'react-spinners';
import { Query } from 'react-apollo';
import { loader as gqlLoader } from 'graphql.macro';
import i18next from 'i18next';

const ListProductVariantQuery = gqlLoader('./query.graphql');

class ListProductVariant extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  render() {
    return (
      <div className="meta-vol">
        <Query
          query={ListProductVariantQuery}
          variables={{ indexname: this.indexName, rootpath: this.props.productid }}
        >
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div className="sweet-loading" style={{ marginTop: '50px', marginBottom: '50px' }}>
                  <DotLoader
                    sizeUnit={'px'}
                    size={50}
                    color={'#8986ca'}
                    loading={!this.state.isLoaded}
                  />
                </div>
              );
            if (error) return <div>{i18next.t('error') + ': ' + error.message}</div>;
            return (
              <>
                {data.productvariant.results &&
                  data.productvariant.results.items.map(function (listItem, index) {
                    return (
                      <>
                        {listItem.item.title && listItem.item.title.value}
                        {index < data.productvariant.results.items.length - 1 && <>,&nbsp;</>}
                      </>
                    );
                  })}
              </>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withSitecoreContext()(ListProductVariant);
