import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ProductGridItem from '../ProductGridItem/index.js';
import { DotLoader } from 'react-spinners';
import { Query } from 'react-apollo';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import i18next from 'i18next';
import ProductGridItemV2 from '../ProductGridItemV2';

const BestRatedProductsQuery = gqlLoader('./BestRatedProductsQuery.graphql');

class BestRatedProducts extends ReactComponent {
  state = {
    error: null,
    isLoaded: false,
    productId: 0,
  };

  SortByRating(x, y) {
    return x.item.rating.numberValue === y.item.rating.numberValue
      ? 0
      : x.item.rating.numberValue < y.item.rating.numberValue
      ? 1
      : -1;
  }

  render() {
    const datasource = this.props.fields || {};
    if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
      let idItem = this.props.sitecoreContext.route.fields.productCategories[0].id
        .split('-')
        .join('');

      const designVariant =
        typeof this.props.fields.designVariant === 'undefined'
          ? ''
          : this.props.fields.designVariant.value;
      let ProductNumber = this.props.fields.ProductNumber.value;

      return (
        <div className={'related products container ' + designVariant}>
          <div className="related-title">
            <h3>
              <span>
                <Text field={this.props.fields.title} />
              </span>
            </h3>
            <ul className="row">
              <Query
                query={BestRatedProductsQuery}
                variables={{
                  indexname: this.indexName,
                  rootPath: 'sitecore/content/' + this.appName + '/',
                  catId: idItem,
                }}
              >
                {({ loading, error, data }) => {
                  if (loading)
                    return (
                      <div
                        className="sweet-loading"
                        style={{ marginTop: '50px', marginBottom: '50px' }}
                      >
                        <DotLoader
                          sizeUnit={'px'}
                          size={25}
                          color={'#003D0E'}
                          loading={!this.state.isLoaded}
                        />
                      </div>
                    );
                  if (error) return <div>{i18next.t('error') + ': ' + error.message}</div>;
                  return (
                    data.search.results.items &&
                    data.search.results.items
                      .filter((item) => item.item.id !== this.state.productId)
                      .sort(this.SortByRating)
                      .slice(0, ProductNumber)
                      .map((product, index) => <ProductGridItemV2 key={index} index={index} product={product} />)
                  );
                }}
              </Query>
            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div className="component-no-datasource">
          {i18next.t('no-datasource') +
            ' ' +
            this.props.rendering.componentName +
            ' ' +
            i18next.t('component')}
        </div>
      );
    }
  }
}

export default withSitecoreContext()(BestRatedProducts);
