import React from 'react';
import { Image, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from '../../ReactComponent';
import i18next from 'i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './relatedProductsCarouselV2.scss';
import StarRating from '../StarRating';
import ProductReviews from '../../utils/ProductReviews';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import NavLinkAdv from '../../utils/NavLinkAdv';


class RelatedProductsCarouselV2 extends ReactComponent {
  state = {
    isLoaded: false,
  };

  // If necessary, it give us one number after the "," to have half star
  calcNumberValue(value) {
    return Math.round(value * 10) / 10;
  }

  render() {
    const datasource = this.props.fields.ProductList;

    if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
      const designVariant =
        typeof this.props.fields.designVariant === 'undefined'
          ? ''
          : this.props.fields.designVariant.value;
      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        draggable: false,
        responsive: [
          {
            breakpoint: 1179,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };

      return (
        <div className={'margin_custom rpc_home ' + designVariant}>
          <div className={'related__product__custom'}>
            <div className="left-side">
              <div className="col-12 heading_v2">
                <Text field={this.props.fields.Heading} tag={'h2'} />
                <Text field={this.props.fields.SubHeading} tag={'h3'} />
              </div>
            </div>
            <div className="right-side">
              <div className="hide-left" />
              <Slider {...settings} className={'sliderCustom '}>
                {this.props.fields.ProductList.map((item, index) => (
                  <div key={`index-${index}`} className="portfolio-item text-center">
                    <NavLinkAdv to={item.url} className="portfolio-link">
                      <div className={'imgSlide'}>
                        <Image loading="lazy" field={item.fields.image1} />
                      </div>
                      <div className="portfolio-caption">
                        <Text field={item.fields.title} tag="h2" className={'titleProduct'} />
                        <Text
                          field={item.fields?.tag[0]?.fields.title}
                          tag="h3"
                          style={{
                            backgroundColor: item.fields?.tag[0]?.fields.backgroundColor.value,
                          }}
                          className={'tags'}
                        />
                        <div className="d-inline-flex stars">
                          <StarRating
                            value={this.calcNumberValue(item.fields.rating?.value)}
                            isLittleSvg={true}
                          />{' '}
                          &nbsp;
                          <span>{this.calcNumberValue(item.fields.rating?.value)}</span> &nbsp;
                          <ProductReviews productId={item.id.replace(/-/g, '').toLowerCase()} />
                        </div>
                      </div>
                    </NavLinkAdv>
                    <NavLinkAdv to={item.url} className="btn btn_custom_oro">
                      {i18next.t('see-more')}
                    </NavLinkAdv>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="component-no-datasource">
          {i18next.t('no-datasource') +
            ' ' +
            this.props.rendering.componentName +
            ' ' +
            i18next.t('component')}
        </div>
      );
    }
  }
}

export default withSitecoreContext()(RelatedProductsCarouselV2);
