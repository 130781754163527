import React from 'react';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import StarRating from '../StarRating';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import i18next from 'i18next';
import NavLinkAdv from '../../utils/NavLinkAdv';
import { productClickTagging } from '../Tagging';

const RelatedProductsQuery = gqlLoader('./RelatedProductsQuery.graphql');

const RelatedProducts = (props) => {
  const datasourceItem = props.fields || {};
  if (datasourceItem !== undefined && Object.getOwnPropertyNames(datasourceItem).length >= 1) {
    const graphQLResult = props.relatedProductsQuery;
    const { datasource } = graphQLResult;

    return (
      <div
        className={
          'container margin_custom ' +
          (props.fields.designVariant && props.fields.designVariant.value)
        }
      >
        <div className="row related__product__custom ">
          <div className="col-md-12 pad">
            <Text field={props.fields.RelatedProductsHeading} tag="h2" />
          </div>
          {datasource &&
            datasource.relatedProductsList?.targetItems.map((productItem, index) => (
              <div
                className="col-md-3 col-sm-6 portfolio-item text-center productTag"
                data-gtm-name={productItem.title.value}
                data-gtm-ean={productItem.codeEan.value}
                data-gtm-category={productItem.productCategories.targetItems[0].title.value}
                data-gtm-rating={productItem.rating.numberValue}
                onClick={() =>
                  productClickTagging(
                    productItem.title.value,
                    productItem.codeEan.value,
                    productItem.productCategories.targetItems[0].title.value,
                    productItem.rating.numberValue
                  )
                }
              >
                <NavLinkAdv to={productItem.url} className="portfolio-link">
                  <Image loading="lazy" field={productItem.image1.jss} className="img-fluid" />

                  <div className="portfolio-caption min_font">
                    <Text field={productItem.title.jss} tag="h3" />
                    <RichText field={productItem.productShortDescription.jss} tag="p" />
                    {productItem.rating.numberValue !== 0 && (
                      <StarRating value={productItem.rating.numberValue} isLittleSvg={true} />
                    )}
                  </div>
                  <div to={productItem.url} className="btn btn_custom_oro">
                    {i18next.t('see-more')}
                  </div>
                </NavLinkAdv>
              </div>
            ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="component-no-datasource">
        {i18next.t('no-datasource') + ' ' + props.rendering.componentName + ' ' +i18next.t('component')}
      </div>
    );
  }
};

export default GraphQLData(RelatedProductsQuery, { name: 'relatedProductsQuery' })(RelatedProducts);
