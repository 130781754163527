import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';

class FilterSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      init: true,
      keyword: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.init && nextProps.facets && nextProps.facets.length !== 0) {
      const url = window.location.href;
      let keyword = '';
      if (url.includes('?search=')) {
        keyword = url.substring(url.indexOf('=') + 1);
        if (keyword.includes('#')) keyword = keyword.replace('#', '');
        this.props.onSearchFilter(keyword);
        this.setState({
          keyword: keyword,
          init: false,
        });
      }
    }
    if (nextProps.keyword !== this.props.keyword) {
      this.setState({
        keyword: nextProps.keyword,
      });
    } else {
      this.setState({
        keyword: '',
      });
    }
  }

  handleChange(e) {
    this.setState({
      keyword: e.target.value,
    });
    this.props.onSearchFilter(e.target.value);
  }

  render() {
    const designVariant =
      typeof this.props.fields.designVariant === 'undefined'
        ? ''
        : this.props.fields.designVariant.value;

    return (
      <div className={'widget widget_search ' + designVariant}>
        <Text field={this.props.fields.title} tag="h4" className="widget-title" />
        <label className="sr-only">Search</label>
        <input
          type="search"
          className="form-control"
          placeholder="Search something…"
          value={this.state.keyword}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default FilterSearch;
