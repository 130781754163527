import React from 'react';
import {Placeholder, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import { StickyContainer } from 'react-sticky';
import './productFinder.scss';

class ProductFinderLayoutWrapper extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      facets: [],
      filters: [],
      doResetFilter: false,
      rangeFilter: ["*","*"],
      updateRange: true,
      keyword: "",
      resetSearch: false
    };
    this.onFacetsChange = this.onFacetsChange.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.onRangeChange = this.onRangeChange.bind(this);
    this.onKeywordChange = this.onKeywordChange.bind(this);
    this.displayFilters = this.displayFilters.bind(this);
  }

  componentDidUpdate(nextProps){
    if(nextProps.sitecoreContext.route.name !== this.props.sitecoreContext.route.name){
        this.resetFilters()
    }
  }

  onFacetsChange(facets) {
    this.setState({
      facets: facets,
      doResetFilter: false
    });
  }

  onRangeChange(range) {
    this.setState({
      rangeFilter: range,
      updateRange: false,
      doResetFilter: false
    });
  }

  onKeywordChange(keyword) {
    this.setState({
      keyword: keyword
    });
  }

  onFiltersChange(filters) {
    if (this.state.filters.length === 0) {
      this.setState({
        filters: [...this.state.filters, filters],
        updateRange: true,
        doResetFilter: false
      })
    }
    else {
      let found = false;
      let updatedFilters = this.state.filters.map(obj => {
        if (obj.template === filters.template) {
          found = true;
          return(filters);
        }
        else return(obj);
      })
      if (!found) {
        updatedFilters.push(filters);
      }
      this.setState({
        filters: updatedFilters,
        updateRange: true,
        rangeFilter: found ? ["*","*"] : this.state.rangeFilter,
        doResetFilter: false
      })
    }
  }

  resetFilters() {
    if (this.state.resetSearch === true) {
      this.setState({
        resetSearch: false
      });
    }else{
      this.setState({
        resetSearch: false
      });
    }
    this.setState({
      filters: [],
      rangeFilter: ["*","*"],
      keyword: "",
      doResetFilter: true
    });
  }

  displayFilters(){
    let filters = document.getElementById("filters")
    filters.classList.toggle('sh');
  }

  render() {

    
    return(
        <div className="commerce productFinder">
          <StickyContainer className="row">
            <div onClick={this.displayFilters} className="displayFilters">
              FILTRES
            </div>
            <div className="col-lg-3 col-md-12 sidebar-wrap filters" id={"filters"}>
              <div className="closeFilters" onClick={this.displayFilters}/>
              <div className="main-sidebar no-border">
                <Placeholder name="sidebar-left" rendering={this.props.rendering} facets={this.state.facets}
                  onFiltersChange={this.onFiltersChange} onRangeChange={this.onRangeChange}
                  resetFilters={this.state.doResetFilter} resetSearch={this.state.resetSearch}
                  updateRange={this.state.updateRange} range={this.state.rangeFilter} onKeywordChange={this.onKeywordChange}
                  />
                <div className="clear-filters" onClick={this.resetFilters}>
                  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
                    <g id="IconRéinitialiser">
                      <path d="M18.607 7.1935C18.5305 6.899 18.2285 6.722 17.936 6.8C17.642 6.8765 17.466 7.177 17.5425 7.471C17.7215 8.158 17.8125 8.869 17.8125 9.584C17.8125 14.1915 14.0635 17.9405 9.456 17.9405C4.8485 17.9405 1.0995 14.1915 1.0995 9.584C1.0995 4.9765 4.8485 1.2275 9.456 1.2275C11.56 1.2275 13.5775 2.024 15.113 3.436L12.853 3.436C12.5495 3.436 12.3035 3.6825 12.3035 3.986C12.3035 4.2895 12.5495 4.536 12.853 4.536L16.8395 4.536L16.8395 0.55C16.8395 0.246 16.5935 0 16.2895 0C15.986 0 15.74 0.246 15.74 0.55L15.74 2.526C14.017 0.994 11.7845 0.128 9.456 0.128C4.242 0.128 0 4.37 0 9.584C0 14.7985 4.242 19.04 9.456 19.04C14.6705 19.04 18.912 14.7985 18.912 9.584C18.912 8.775 18.8095 7.971 18.607 7.1935" transform="translate(0.49995 0.49985)" id="Fill-1" fill="#6F6F6F" fillRule="evenodd" stroke="none" />
                    </g>
                  </svg>
                  Réinitialiser les filtres
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-12 main-wrap" id="products">
              <div className="main-content">
                <div className="woo-content">
                  <Placeholder name="main-content" rendering={this.props.rendering} resetFilters={this.state.doResetFilter} filters={this.state.filters}
                    rangeFilter={this.state.rangeFilter} onFacetsChange={this.onFacetsChange} keyword={this.state.keyword}
                    onSearchFilter={this.onKeywordChange}/>
                </div>
              </div>
            </div>
          </StickyContainer>
        </div>
    );
  }
}

export default withSitecoreContext()(ProductFinderLayoutWrapper);
